.App {
  text-align: center;
  margin-top: 50px;
}

.search-name {
  width: 400px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.dicta-container {
  text-align: center;
  margin-top: 100px;
}
.api-key-input {
  text-align: right;
  align-self: flex-end;
}

.start-btn {
  margin: right 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CHAT CSS  */
.chat-container {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 80px;
  max-width: 100%;
  /* height: 100vh; */
  height: 100%;
}

.strt-rst-btn-wrapper {
  margin: 0 80px;
  max-width: 100%;
}

.speak-disabled.btn:disabled {
  color: black;
  pointer-events: none;
  background-color: grey;
  border-color: grey;
  opacity: var(--bs-btn-disabled-opacity);
}

.user-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.user-message {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
}

.bot-container {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.bot-response {
  background-color: #2196f3;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
}

.conversation {
  display: flex;
  flex-direction: column;
}

.user-question {
  align-self: flex-end;
  margin-right: 30px;
  /* background-color: #4caf50;
  color: white; */
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
  border-color: #a3cfbb;
  color: #0a3622;
  background: #d1e7dd;
}

.bot-answer {
  align-self: flex-start;
  margin-left: 30px;
  background-color: #2196f3;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
  width: 70%;
}

.disable-btn {
  margin: 10px;
  cursor: pointer;
  background: none;
  border: none;
  color: #165283;
  font-weight: 700;
  font-size: 18px;
}

.microphone {
  margin: 10px;
  cursor: pointer;
  background: none;
  border: none;
  color: #165283;
  font-weight: 700;
  font-size: 18px;
}
.transcript-btn {
  margin: 10px;
  cursor: pointer;
  background: none;
  border: none;
  color: #bf1f1f;
  font-weight: 700;
  font-size: 18px;
}

.disable-btn:hover {
  color: #bf1f1f;
  text-decoration: underline;
}

.reset-btn {
  align-self: flex-end;
}

.margin-cls {
  margin-left: -42px;
}
