/* ChatLoader.css */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure it takes up the full height of its container */
}

.dot {
  width: 10px;
  height: 10px;
  margin: 2.5px;
  border-radius: 50%;
  background-color: #e1e3e7;
  display: inline-block;
  animation: slide 1s infinite linear; /* Increased duration to 1 second */
  border: 1px transparent; /* Prevent leftward movement */
}

.dot:nth-child(1) {
  background-color: #2ab46f; /* Light color for the first dot */
}

.dot:nth-child(2) {
  background-color: #ab1522; /* Light color for the second dot */
}

.dot:nth-child(3) {
  background-color: #7299f5; /* Light color for the third dot */
}

@keyframes slide {
  0% {
    transform: translateX(0); /* Start from the current position */
  }
  50% {
    transform: translateX(10px); /* Move to the right */
  }
  100% {
    transform: translateX(0); /* Return to the starting position */
  }
}
